import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/model',
    name: 'model',
    component: () => import('../views/ModelView.vue')
  },
  {
    path: '/training',
    name: 'training',
    component: () => import('../views/TrainingView.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/VideoView.vue')
  },
  {
    path: '/bible-themes',
    name: 'bible-themes',
    component: () => import('../views/BiblicalThemesView.vue')
  },
  {
    path: '/advent-series',
    name: 'advent-series',
    component: () => import('../views/AdventSeriesView.vue')
  },
  {
    path: '/god-series',
    name: 'god-series',
    component: () => import('../views/GodSeriesView.vue')
  },
  {
    path: '/new-testament',
    name: 'new-testament',
    component: () => import('../views/NewTestamentView.vue')
  },
  {
    path: '/old-testament',
    name: 'old-testament',
    component: () => import('../views/OldTestamentView.vue')
  },
  {
    path: '/luke-acts-miniseries',
    name: 'luke-acts-miniseries',
    component: () => import('../views/LukeActsMiniseriesView.vue')
  },
  {
    path: '/torah-series',
    name: 'torah-series',
    component: () => import('../views/TorahSeriesView.vue')
  },
  {
    path: '/wisdom-series',
    name: 'wisdom-series',
    component: () => import('../views/WisdomSeriesView.vue')
  },
  {
    path: '/audio',
    name: 'audio',
    component: () => import('../views/AudioView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/training-one',
    name: 'training-one',
    component: () => import('../views/training/BookOneView')
  },
  {
    path: '/training-two',
    name: 'training-two',
    component: () => import('../views/training/BookTwo.vue')
  },
  {
    path: '/training-three',
    name: 'training-three',
    component: () => import('../views/training/BookThree.vue')
  },
  {
    path: '/training-four',
    name: 'training-four',
    component: () => import('../views/training/BookFour.vue')
  },
  {
    path: '/training-five',
    name: 'training-five',
    component: () => import('../views/training/BookFive.vue')
  },
  {
    path: '/training-six',
    name: 'training-six',
    component: () => import('../views/training/BookSix.vue')
  },
  {
    path: '/training-seven',
    name: 'training-seven',
    component: () => import('../views/training/BookSeven.vue')
  },
  {
    path: '/training-eight',
    name: 'training-eight',
    component: () => import('../views/training/BookEight.vue')
  },
  {
    path: '/training-nine',
    name: 'training-nine',
    component: () => import('../views/training/BookNine.vue')
  },
  {
    path: '/training-ten',
    name: 'training-ten',
    component: () => import('../views/training/BookTen.vue')
  },
  {
    path: '/training-eleven',
    name: 'training-eleven',
    component: () => import('../views/training/BookEleven.vue')
  },
  {
    path: '/training-twoelve',
    name: 'training-twoelve',
    component: () => import('../views/training/BookTwoelve.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
